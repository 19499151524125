const firebaseConfig = {
  apiKey: 'AIzaSyB_0YpjpzQ4zALUa78jREXnryVanus1bew',
  authDomain: 'msf-mapswipe.firebaseapp.com',
  databaseURL: 'https://msf-mapswipe.firebaseio.com',
  projectId: 'msf-mapswipe',
  storageBucket: 'msf-mapswipe.appspot.com',
  appId: '1:122222367696:web:8d3d9d3c7f77c3ce',
};

export default firebaseConfig;
